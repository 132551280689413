import axios from 'axios'

const apiModule = {
  state: {
    menuDataapi: []
  },
  getters: {
    getCategoryByIdapi: state => cateId => {
      return state.menuDataapi.filter(category => {
        return parseInt(category.id) === parseInt(cateId)
      })
    }
  },
  mutations: {
    UPDATE_MENU_DATA_API(state, dataMenu) {
      state.menuDataapi = dataMenu
    }
  },
  actions: {
    async fetchMenuapi(content) {
      try {
        const reponse = await axios.get(
          'https://partner1.triggersplus.com/dining/get_menus/6096AA46AAFC4931A3418165DE1741B0/-9999999/0008843251/json/1/'
        )
        // const exceptCategory = ['5', '6']
        const menuData = reponse.data.datas
        const filterMenuData = menuData.filter(item => {
          // return !exceptCategory.includes(item.id)
          return item.active_emenu === true
        })
        console.log(filterMenuData)
        if (filterMenuData.length !== 0) {
          content.commit('UPDATE_MENU_DATA_API', filterMenuData)
          console.log(menuData)
        } else {
          content.commit('UPDATE_MENU_DATA_API', menuData)
        }
      } catch (err) {
        console.log(err)
      }
    }
  },
  modules: {}
}

export default apiModule
